import React, { FC, useEffect, useMemo, useState } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import {
  Dialog,
  Text,
  TextPriority as TEXT_PRIORITY,
  Button,
  ButtonPriority as PRIORITY,
  TextField,
} from 'wix-ui-tpa';

import { debounce } from 'lodash';

import { classes, st } from './EditProfileUrlModal.st.css';
import {
  useMember,
  useMemberActions,
  useSiteContext,
  useUiActions,
  useUiState,
} from '../../../../../contexts/widget';
import { DataHook } from '../../../../../constants/DataHook';
import { ModalType } from '../../../../../types';
import { useBi } from '../../../../../hooks/useBi';
import { LoadingIcon } from '../../common/icons/Loading/Loading';

const MAX_SLUG_LENGTH = 255;

export const EditProfileUrlModal: FC<ModalType> = ({ onClose, isOpen }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { updateSlug } = useMemberActions();
  const initialSlug = useMember().profile.slug;
  const { isAvailable, isLoading } = useUiState().slug;
  const { querySlugAvailability, setSlugAvailability, setSlugLoading } =
    useUiActions();
  const [newSlug, setNewSlug] = useState(initialSlug);
  const [error, setError] = useState<string | null>();
  const [isValid, setIsValid] = useState(true);
  const [inputClassName, setInputClassName] = useState(classes.urlInput);
  const { baseUrl } = useSiteContext();
  const biLogger = useBi();

  const isFieldChanged = newSlug !== initialSlug;
  const isConfirmDisabled = !isFieldChanged || !!error || isLoading;

  useEffect(() => {
    if (newSlug === initialSlug) {
      setError(null);
    } else if (!isValid) {
      setError(t('app.widget.modals.change-profile-url.invalid-input'));
    } else if (!isAvailable) {
      setError(t('app.widget.modals.change-profile-url.slug-not-available'));
    } else {
      setError(null);
    }
  }, [isAvailable, t, newSlug, initialSlug, isValid]);

  const checkSlugAvailability = useMemo(
    () => debounce(querySlugAvailability, 500),
    [querySlugAvailability],
  );

  const resetErrors = () => {
    setSlugAvailability(true);
    setError(null);
  };

  const onConfirm = async () => {
    updateSlug(newSlug);
    biLogger.profileEdited({ urlChanged: true });
    resetErrors();
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        resetErrors();
        onClose();
      }}
      contentClassName={st(classes.root, { mobile: isMobile })}
      data-hook={DataHook.EditProfileUrlModal}
      wiredToSiteColors={false}
    >
      <Text className={classes.title} tagName="div">
        {t('app.widget.modals.change-profile-url.title')}
      </Text>
      <Text className={classes.mainText} tagName="div">
        {t('app.widget.modals.change-profile-url.content')}
      </Text>
      <Text className={classes.inputLabel} tagName="div">
        {t('app.widget.modals.change-profile-url.input-label')}
      </Text>
      <TextField
        value={newSlug}
        maxLength={MAX_SLUG_LENGTH}
        className={inputClassName}
        data-hook={DataHook.EditProfileUrlSlugInput}
        onChange={(changeEvent) => {
          const newValue = changeEvent.target.value;
          const isInputValid = /^[a-z0-9-]+$/i.test(newValue);

          if (isInputValid) {
            setIsValid(true);
            setSlugLoading(true);
            checkSlugAvailability(newValue);
          } else {
            setIsValid(false);
            checkSlugAvailability.cancel();
            setSlugLoading(false);
          }

          setNewSlug(newValue);
        }}
        suffix={isLoading && <LoadingIcon />}
        errorMessage={error ?? ''}
        error={!isLoading && !!error}
        success={isFieldChanged && !isLoading && !error}
        successIcon={isFieldChanged && !isLoading && !error}
        onFocus={() => isMobile && setInputClassName(classes.urlInputFocus)}
        onBlur={() => isMobile && setInputClassName(classes.urlInput)}
      />
      <div className={classes.fullUrlContainer}>
        <Text className={classes.baseUrl} priority={TEXT_PRIORITY.secondary}>
          {baseUrl + '/profile/'}
        </Text>
        <Text className={classes.slug}>{newSlug}</Text>
        {newSlug && (
          <Text className={classes.baseUrl} priority={TEXT_PRIORITY.secondary}>
            /profile
          </Text>
        )}
      </div>
      <div className={classes.ctaContainer}>
        <Button
          upgrade
          priority={PRIORITY.basicSecondary}
          className={st(classes.button, { secondary: true })}
          onClick={onClose}
        >
          {t('app.widget.modals.change-profile-url.discard')}
        </Button>
        <Button
          upgrade
          className={st(classes.button, {
            primary: true,
            disabled: isConfirmDisabled,
          })}
          onClick={onConfirm}
          disabled={isConfirmDisabled}
        >
          {t('app.widget.modals.change-profile-url.confirm')}
        </Button>
      </div>
    </Dialog>
  );
};
