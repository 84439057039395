type Props = {
  isSocial: boolean;
  isPublicProfile: boolean;
  isPrivacyEditable: boolean;
  index: number | undefined;
};

export const getShouldShowPrivacyIcon = ({
  isSocial,
  isPublicProfile,
  isPrivacyEditable,
  index,
}: Props) => isSocial && isPublicProfile && isPrivacyEditable && !index;
