import React, { ChangeEvent, FC } from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { useField } from 'formik';
import { Avatar, Text, Tooltip } from 'wix-ui-tpa';
import Camera from 'wix-ui-icons-common/on-stage/Camera';
import Delete from 'wix-ui-icons-common/on-stage/Delete';
import Replace from 'wix-ui-icons-common/on-stage/Replace';

import { classes, st } from './AvatarInput.st.css';
import { InfoIcon } from '../icons';
import { FieldWrapper, Member } from '../../../../../types';
import { convertImageFileToBase64 } from './convertImageFileToBase64';
import { DataHook } from '../../../../../constants/DataHook';

const getInputId = (hasPicture: boolean, widgetId: string) =>
  hasPicture ? `${widgetId}-replacePicture` : `${widgetId}-uploadPicture`;

export const AvatarInput: FC<Pick<FieldWrapper, 'formikFieldSelector'>> = ({
  formikFieldSelector,
}) => {
  const { t } = useTranslation();
  const { widgetId } = useEnvironment();
  const [formikField, , helpers] = useField<Member>(formikFieldSelector);
  const member = formikField.value;
  const { profile } = member;
  const { name, photo } = profile;
  const hasPicture = !!photo?.url;
  const INPUT_ID = getInputId(hasPicture, widgetId);

  const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      event.target.value = '';
      const image = await convertImageFileToBase64(file);
      helpers.setValue({
        ...member,
        profile: {
          ...profile,
          photo: image,
        },
      });
    }
  };

  const handleImageDelete = () =>
    helpers.setValue({
      ...member,
      profile: {
        ...profile,
        photo: {},
      },
    });

  return (
    <div className={st(classes.root, { hasPicture })}>
      <div className={classes.textAndTooltipWrapper}>
        <Text className={classes.text}>
          {t('app.widget.sections.public.profile-image')}
        </Text>
        <Tooltip
          className={classes.tooltip}
          content={t('app.widget.sections.public.profile-image.tooltip')}
          contentClassName={classes.tooltipContent}
        >
          <InfoIcon />
        </Tooltip>
      </div>
      <div className={classes.avatarWrapper}>
        <Avatar
          data-hook={DataHook.Avatar}
          src={photo?.url}
          className={classes.avatar}
          name={name}
        />
        <input
          id={INPUT_ID}
          className={classes.uploadInput}
          type="file"
          accept="image/*"
          aria-label={t('profile-widget.change-profile-photo')}
          onChange={handleImageUpload}
        />
        <label htmlFor={INPUT_ID} className={classes.uploadLabel} />
        {hasPicture ? (
          <div className={classes.pictureWrapper}>
            <div className={classes.deleteAndReplaceIconWrapper}>
              <div className={classes.iconOval}>
                <Replace />
              </div>
              <div className={classes.iconOval}>
                <Delete onClick={handleImageDelete} />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.pictureWrapper}>
            <div className={classes.iconOval}>
              <Camera />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
