import { Address, Member } from '@wix/ambassador-members-v1-member/types';
import {
  CustomField,
  Section,
} from '@wix/ambassador-members-v1-custom-field/types';
import { FieldPrivacy } from '@wix/ambassador-members-v1-member-custom-field-privacy/types';
import {
  ADDRESS_FIELD_KEY,
  ADDRESS_LINE_FIELD_KEY,
  addressSubfieldsPresets,
  CITY_FIELD_KEY,
  COUNTRY_FIELD_KEY,
  POSTAL_CODE_FIELD_KEY,
  SUBDIVISION_FIELD_KEY,
} from '../constants';
import { AddressSubfield, FieldType } from '../../types';
import { getCustomFieldWithValue } from './customFields';

const emptyAddress: Address = {
  addressLine: undefined,
  country: undefined,
  city: undefined,
  postalCode: undefined,
  subdivision: undefined,
};

const getAddressSubfieldPreset = (presetKey: string) =>
  addressSubfieldsPresets.find((preset) => preset.key === presetKey)!;

const getAddressSubfield = (
  contactAddress: Address,
  value: AddressSubfield['value'],
  presetKey: string,
): AddressSubfield => {
  const addressSubfieldPreset = getAddressSubfieldPreset(presetKey);

  return {
    id: `${contactAddress.id ?? ''}-${addressSubfieldPreset.propName}`,
    addressId: contactAddress.id!,
    key: addressSubfieldPreset.key,
    type: addressSubfieldPreset.type,
    value,
  };
};

function nullToUndefined<T>(value: T | null): T | undefined {
  return value === null ? undefined : value;
}

const getAddressLineSubfield = (contactAddress: Address) =>
  getAddressSubfield(
    contactAddress,
    nullToUndefined(contactAddress.addressLine),
    ADDRESS_LINE_FIELD_KEY,
  );

const getCitySubfield = (contactAddress: Address) =>
  getAddressSubfield(
    contactAddress,
    nullToUndefined(contactAddress.city),
    CITY_FIELD_KEY,
  );

const getPostalCodeSubfield = (contactAddress: Address) =>
  getAddressSubfield(
    contactAddress,
    nullToUndefined(contactAddress.postalCode),
    POSTAL_CODE_FIELD_KEY,
  );

const getCountrySubfield = (contactAddress: Address) =>
  getAddressSubfield(
    contactAddress,
    {
      options: [],
      selectedId: nullToUndefined(contactAddress.country),
    },
    COUNTRY_FIELD_KEY,
  );

const getSubdivisionSubfield = (contactAddress: Address) =>
  getAddressSubfield(
    contactAddress,
    {
      options: [],
      selectedId: nullToUndefined(contactAddress.subdivision),
    },
    SUBDIVISION_FIELD_KEY,
  );

export const isAddressesField = (field: Pick<CustomField, 'key'>) =>
  !!field.key?.startsWith(ADDRESS_FIELD_KEY);

export const getAddressFields = (
  field: CustomField,
  member: Member,
  fieldsPrivacies: FieldPrivacy[],
) => {
  const addressesWithId = member.contact?.addresses?.filter(
    (address) => address.id,
  );
  const memberAddresses = addressesWithId?.length
    ? addressesWithId
    : [emptyAddress];

  return memberAddresses.map((contactAddress, addressFieldIndex) => {
    const subfields = [
      getAddressLineSubfield(contactAddress),
      getCitySubfield(contactAddress),
      getPostalCodeSubfield(contactAddress),
      getCountrySubfield(contactAddress),
      getSubdivisionSubfield(contactAddress),
    ];

    return getCustomFieldWithValue({
      field: {
        ...field,
        section: Section.ADDRESS,
        id: `${field.id}${addressFieldIndex ? addressFieldIndex : ''}`,
      },
      fieldsPrivacies,
      value: subfields,
      index: addressFieldIndex,
      fieldType: FieldType.ADDRESS,
    });
  });
};
