import React, { FC } from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { FieldGroup } from '../../FieldGroup';
import { SectionHeader } from '../../common/SectionHeader';
import { classes } from './Account.st.css';
import { LoginEmail } from './LoginEmail';
import { useFields } from '../../../../../contexts/widget';
import settingsParams from '../../../settingsParams';
import { FieldSection } from '../../../../../types';

export const Account: FC = () => {
  const fields = useFields();
  const settings = useSettings();

  return (
    <div className={classes.root}>
      <SectionHeader
        title={settings.get(settingsParams.accountSectionTitle)}
        subtitle={settings.get(settingsParams.accountSectionSubtitle)}
      />
      <LoginEmail />
      <FieldGroup fields={fields} section={FieldSection.GENERAL} />
    </div>
  );
};
