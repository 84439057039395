import React, { FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import {
  Dialog,
  Text,
  TextTypography as TYPOGRAPHY,
  Button,
  ButtonPriority as PRIORITY,
} from 'wix-ui-tpa';

import { classes, st } from './ProfilePrivacyModal.st.css';
import { useMember, useMemberActions } from '../../../../../contexts/widget';
import { DataHook } from '../../../../../constants/DataHook';
import { ModalType, ProfilePrivacyStatus } from '../../../../../types';
import { useBi } from '../../../../../hooks/useBi';

export const ProfilePrivacyModal: FC<ModalType> = ({ onClose, isOpen }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { privacyStatus } = useMember();
  const { makeProfilePrivate, makeProfilePublic } = useMemberActions();
  const biLogger = useBi();

  const isPublicProfile = privacyStatus === ProfilePrivacyStatus.PUBLIC;
  const content = isPublicProfile
    ? t('app.widget.modals.profile-privacy.private.content')
    : t('app.widget.modals.profile-privacy.public.content');
  const title = isPublicProfile
    ? t('app.widget.modals.profile-privacy.private.title')
    : t('app.widget.modals.profile-privacy.public.title');

  const onConfirm = () => {
    if (isPublicProfile) {
      makeProfilePrivate();
      biLogger.leftCommunity();
    } else {
      makeProfilePublic();
      biLogger.joinedCommunity();
    }
    onClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      notFullscreenOnMobile
      className={st(classes.root, { mobile: isMobile })}
      data-hook={DataHook.ProfilePrivacyModal}
      wiredToSiteColors={false}
    >
      <div className={classes.content}>
        <Text typography={TYPOGRAPHY.largeTitle} className={classes.title}>
          {title}
        </Text>
        <Text
          typography={TYPOGRAPHY.listText}
          className={classes.textContainer}
          tagName="div"
        >
          {content}
        </Text>
        <div className={classes.ctaContainer}>
          <Button
            upgrade
            priority={PRIORITY.basicSecondary}
            className={st(classes.button, { secondary: true })}
            onClick={onClose}
          >
            {t('app.widget.modals.profile-privacy.secondary-action')}
          </Button>
          <Button
            upgrade
            className={st(classes.button, { primary: true })}
            onClick={onConfirm}
          >
            {t('app.widget.modals.profile-privacy.primary-action')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
